const JUMIO_EVENTS = {
  STARTED: 'workflow:start',
  SUCCESS: 'workflow:success',
  FAILED: 'workflow:failed'
} as const;

const DATA_CENTER = {
  SG: 'sgp',
  US: 'us'
} as const;

enum JumioCallbackStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export { delay };
export { JumioCallbackStatus };
export { JUMIO_EVENTS, DATA_CENTER };
